import barba from "@barba/core";
import "core-js/stable";
import "regenerator-runtime/runtime";
import Animations from "./js/Animations";
import Slider from "./js/Slider";
import Form from "./js/Form";
import { gsap } from "gsap/dist/gsap";
import Menu from "./js/Menu";
import Subscribe from "./js/Subscribe";
import Contact from "./js/contact-form";
import Headroom from "headroom.js";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./app.scss";
import Popup from "./js/Popup";
import BlurHover from "./js/BlurHover";

// Animations
import "./js/Animations/Navigation";
import "./js/Animations/Header";
import "./js/Animations/Text-images";
import "./js/Animations/Text-image";
import "./js/Animations/Get-started";

let actions = {};
let actionChain = new Map();
function registerAction(action) {
  actions[action.name] = {
    mount: action,
  };
}

registerAction(Animations);

[...document.querySelectorAll(".blur-hover")].map((el) => BlurHover(el));

gsap.registerPlugin(ScrollTrigger);

barba.hooks.beforeEnter((data) => {});

barba.hooks.after((data) => {
  document.body.classList.remove("overflow-hidden");
  window.scroll(0, 0);
  runActions();
});

runActions();

function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}

function runActions() {
  console.log("runActions");
  action("Form", Form);
  action("Contact", Contact);
  // action("Slider", Slider);
  document
    .querySelectorAll('[data-action="Slider"]')
    .forEach((el) => Slider(el));

  Popup(document.querySelectorAll(".popup-btn"));
  Contact(document.querySelectorAll(".contact-btn"));
  Menu(document.querySelectorAll(".menu-btn"));
  Subscribe(document.querySelectorAll(".subscribe-btn"));
  // Popup(document.querySelectorAll(".popup-btn"));

  const headroomElement = document.querySelector("#main-header");
  const headroom = new Headroom(headroomElement, {
    offset: 200,
  });
  headroom.init();

  const modelBtn = document.querySelectorAll(".model-btn");
  const modelClose = document.querySelectorAll(".model-close");

  if (modelClose) {
    modelClose.forEach((btn) =>
      btn.addEventListener("click", (e) => {
        console.log(e.target.closest(".model"));
        e.target.closest(".model").classList.add("hidden");
        document.body.classList.remove("overflow-hidden");
      })
    );
  }
}
