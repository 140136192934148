const getOffset = (element, horizontal = false) => {
  if (!element) return 0;
  return (
    getOffset(element.offsetParent, horizontal) +
    (horizontal ? element.offsetLeft : element.offsetTop)
  );
};

export default function BlurHover(el) {
  const hoverEl = el.querySelector("#div_moving");

  el.addEventListener("mousemove", (e) => {
    const x = e.pageX - el.offsetLeft;
    const y = e.pageY - getOffset(el);

    const xPercent = (x / el.offsetWidth) * 100;
    const yPercent = (y / el.offsetHeight) * 100;

    // el.lastElementChild.style.clipPath = `circle(${baseSize}% at ${xPercent}% ${yPercent}%)`;
    hoverEl.style.left = `${xPercent}%`;
    hoverEl.style.top = `${yPercent}%`;
  });
}
