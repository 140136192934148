import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import eventBus from "./utils/eventBus";
gsap.registerPlugin(ScrollTrigger);

export default function Animations(el) {
  let tls = [];

  let proxy = { skew: 0 },
    skewSetter = gsap.quickSetter(".projects-list__card", "skewY", "deg"), // fast
    clamp = gsap.utils.clamp(-20, 20); // don't let the skew go beyond 20 degrees.

  ScrollTrigger.create({
    onUpdate: (self) => {
      let skew = clamp(self.getVelocity() / -500);
      // only do something if the skew is MORE severe. Remember, we're always tweening back to 0, so if the user slows their scrolling quickly, it's more natural to just let the tween handle that smoothly rather than jumping to the smaller skew.
      if (Math.abs(skew) > Math.abs(proxy.skew)) {
        proxy.skew = skew;
        gsap.to(proxy, {
          skew: 0,
          duration: 0.8,
          ease: "power3",
          overwrite: true,
          onUpdate: () => skewSetter(proxy.skew),
        });
      }
    },
  });

  // make the right edge "stick" to the scroll bar. force3D: true improves performance
  gsap.set(".projects-list__card", {
    transformOrigin: "center center",
    force3D: true,
  });

  document.querySelectorAll(".logos .logo").forEach((logo) => {
    let ltl = gsap
      .timeline({
        scrollTrigger: {
          trigger: logo,
          start: "top bottom",
          scrub: 1,
        },
      })
      .fromTo(
        logo,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          scale: 1,
        }
      );

    tls.push(ltl);
  });

  let tl3 = gsap
    .timeline({
      scrollTrigger: {
        trigger: ".shutters",
        scrub: 1,
        // start: "top bottom",
        // end: "100%",
      },
    })
    .fromTo(
      ".shutters",
      {
        translateX: "0%",
      },
      {
        translateX: "-100%",
      }
    );

  tls.push(tl3);

  eventBus.on("scrolltrigger:refresh", () => ScrollTrigger.refresh());

  return () => {
    tls.forEach((tl) => tl?.kill?.());
  };
}

const getOffset = (element, horizontal = false) => {
  if (!element) return 0;
  return (
    getOffset(element.offsetParent, horizontal) +
    (horizontal ? element.offsetLeft : element.offsetTop)
  );
};

function blurReveal(el) {
  const baseSize = 40;
  el.lastElementChild.style.clipPath = `circle(${baseSize}% at 50% 50%)`;
  el.addEventListener("mousemove", (e) => {
    const x = e.pageX - getOffset(e.currentTarget, true);
    const y = e.pageY - getOffset(e.currentTarget);
    const xPercent = (x / e.currentTarget.offsetWidth) * 150;
    const yPercent = (y / e.currentTarget.offsetHeight) * 150;

    el.lastElementChild.style.clipPath = `circle(${baseSize}% at ${xPercent}% ${yPercent}%)`;
  });
}

[...document.querySelectorAll(".blur-reveal")].map((el) => blurReveal(el));

gsap.from(".fade-up", {
  duration: 1,
  y: "100%",
});

gsap.from(".fade-down2", {
  duration: 1,
  opacity: 0,
  y: "-100",
});

gsap.from(".header-title", {
  duration: 0.5,
  opacity: 0,
  delay: 0.5,
  y: "100%",
});
gsap.from(".button-fade1", {
  duration: 1,
  opacity: 0,
  delay: 0.5,
});
gsap.from(".nav-fade-in", {
  duration: 0.5,
  opacity: 0,
  delay: 0.4,
  y: "-100%",
});
gsap.from(".button-fade2", {
  duration: 1,
  opacity: 0,
  delay: 2.5,
});

gsap.to("progress", {
  value: 100,
  ease: "none",
  scrollTrigger: {
    trigger: "#mySection",
    scrub: 0.3,
  },
});

gsap.from(".x-fade-left", {
  duration: 1,
  x: "-100%",
});

gsap.from(".logo-fade-in", {
  duration: 2,
  scale: "50",
  rotate: 5000,
});

gsap.from(".fade-down", {
  y: "-100%",
  duration: 0.5,
  delay: 1,
});

gsap.from(".fade-left", {
  opacity: 0,
  scrollTrigger: {
    trigger: ".fade-left",
    start: "top 60%",
    end: "center 50%",

    scrub: 2,
    toggleActions: "restart pause resume reset",
  },
});

gsap.from(".fade-up-heading", {
  opacity: 0,
  delay: 2,
  scrollTrigger: {
    trigger: ".fade-up-heading",
    start: "top 50%",
    end: "top 40%",
    scrub: 2,
    toggleActions: "restart pause resume reset",
  },
});

gsap.from(".big-text-fade-1", {
  opacity: 0,
  delay: 2,
  y: "200",
  scrollTrigger: {
    trigger: ".big-text-fade-1",
    start: "top 100%",
    end: "top 90%",
    scrub: 2,
    toggleActions: "restart pause resume reset",
  },
});

gsap.from(".big-text-fade-2", {
  opacity: 0,
  delay: 2.5,
  y: "200",
  scrollTrigger: {
    trigger: ".big-text-fade-2",
    start: "top 100%",
    end: "top 90%",
    scrub: 2,
    toggleActions: "restart pause resume reset",
  },
});

gsap.from(".big-text-fade-3", {
  opacity: 0,
  delay: 2,
  y: "200",
  scrollTrigger: {
    trigger: ".big-text-fade-3",
    start: "top 100%",
    end: "top 90%",
    scrub: 2,
    toggleActions: "restart pause resume reset",
  },
});

gsap.from(".fade-up-flexed", {
  opacity: 0,
  delay: 2,
  y: "200",
  scrollTrigger: {
    trigger: ".fade-up-flexed",
    start: "top 100%",
    end: "top 80%",
    scrub: 2,
    toggleActions: "restart pause resume reset",
  },
});

gsap.from(".subheading-fadein-1", {
  opacity: 0,
  delay: 2.5,
  scrollTrigger: {
    trigger: ".subheading-fadein-1",
    start: "top 70%",
    end: "top 60%",
    markers: false,
    scrub: 2,
    toggleActions: "restart pause resume reset",
  },
});

gsap.from(".subheading-fadein-2", {
  opacity: 0,
  delay: 2.5,
  scrollTrigger: {
    trigger: ".subheading-fadein-2",
    start: "top 70%",
    end: "top 60%",
    markers: false,
    scrub: 2,
    toggleActions: "restart pause resume reset",
  },
});

gsap.from(".subheading-fadein", {
  opacity: 0,
  delay: 2.5,
  stagger: 2,
  scrollTrigger: {
    trigger: ".subheading-fadein",
    start: "top 70%",
    end: "top 60%",
    markers: false,
    scrub: 2,
    toggleActions: "play pause resume reset",
  },
});

gsap.from(".fade-up-button", {
  opacity: 0,
  delay: 2.5,
  scrollTrigger: {
    trigger: ".fade-up-button",
    start: "top 75%",
    end: "top 65%",
    markers: false,
    scrub: 2,
    toggleActions: "restart pause resume reset",
  },
});

gsap.from(".text-image-left", {
  opacity: 0,
  x: "-100%",
  scrollTrigger: {
    trigger: ".text-image-left",
    start: "top 80%",
    end: "top 90%",
    scrub: 2,
    toggleActions: "restart pause resume reset",
  },
});

gsap.from(".text-image-right", {
  opacity: 0,
  x: "100%",
  scrollTrigger: {
    trigger: ".text-image-right",
    start: "top 80%",
    end: "top 90%",
    scrub: 2,
    toggleActions: "restart pause resume reset",
  },
});

gsap.from(".background-change", {
  background: "#000",
  scrollTrigger: {
    trigger: ".background-change",
    start: "top 80%",
    end: "center 60%",
    scrub: 2,
    toggleActions: "play none none none",
  },
});

gsap.from(".fade-in-img", {
  y: "100%",
  opacity: 0,
  stagger: 1,
  duration: 3,
  scrollTrigger: {
    trigger: ".fade-in-img",
    start: "top 100%",
    end: "top 60%",
    scrub: 2,
    toggleActions: "restart pause resume reset",
  },
});

gsap.from(".fade-in-circle", {
  opacity: 0,
  stagger: 1,
  scrollTrigger: {
    trigger: ".fade-in-circle",
    start: "top 60%",
    end: "center 55%",

    scrub: 2,
    toggleActions: "restart pause resume reset",
  },
});

gsap.from(".fade-in-features", {
  duration: 1,
  opacity: 0,
  stagger: 2,
  delay: 1,
  scrollTrigger: {
    trigger: ".fade-in-features",
    start: "top 90%",
    end: "top 44%",
    scrub: 2,
    toggleActions: "restart pause resume reset",
  },
});

gsap.from(".blur-fade-in", {
  scale: 2.5,
  scrollTrigger: {
    trigger: ".blur-fade-in",
    start: "top 80%",
    end: "top 10%",
    scrub: 5,
    toggleActions: "restart pause resume reset",
  },
});

// gsap.from(".button-fade-in-1", {
//   stagger: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".button-fade-in-1",
//     start: "top 100%",
//     end: "top 80%",
//     markers: true,
//     toggleActions: "play pause resume reset",
//   },
// });

var tl = gsap.timeline({});
tl.pause();
tl.set(".loader", {
  display: "block",
})
  .set(".loader__element", {
    transformOrigin: "center right",
  })
  .to(".loader__element", 1, {
    scaleY: 1,
    ease: "expo.inOut",
  })
  .set(".loader__element", {
    transformOrigin: "center bottom",
  })
  .to(".loader__element", 0.6, {
    scaleY: 0,
    ease: "expo.inOut",
    stagger: -0.4,
  })
  .set(".loader", {
    display: "none",
  });

tl.play(0);
